<script>
  import { currency, BTC_PRICE, ETH_PRICE } from "../../store.js";
  import { delta_calculation } from "./helpers";
  import {
    Grid,
    Row,
    Column,
    TooltipDefinition,
    FormGroup,
    NumberInput,
  } from "carbon-components-svelte";

  export let active_strikes = [];
  let current_price = "";
  let price_levels = [];
  let show_neutralizer_flag = false;
  let price_level_spread = 50;
  let current_perpetual = 0;
  let desired_delta = 0;

  export function show_neutralizer() {
    show_neutralizer_flag = !show_neutralizer_flag;
  }

  $: {
    current_price = parseFloat(
      ($currency === "BTC" ? $BTC_PRICE : $ETH_PRICE).toFixed(2)
    );
  }

  $: {
    if (price_level_spread >= 1) {
      let min = current_price - price_level_spread * 10;
      let max = current_price + price_level_spread * 10;

      price_levels = [];

      for (let price = max; price >= min; price -= price_level_spread) {
        let diff = current_price - price;
        let level_price = price;
        let sums = {
          delta: 0,
          ntd: 0,
        };

        active_strikes.forEach((element) => {
          let dh = delta_calculation(price, element);
          sums.delta = sums.delta + dh.delta;
          sums.ntd = sums.ntd + dh.ntd;
        });

        let delta = active_strikes.reduce((a, b) => {
          let result = a + 0;

          if ("raw" in b) {
            result =
              a + (b["delta"] - b["raw"]["greeks"]["gamma"] * diff) * b["size"];
          }

          return result;
        }, 0);

        if (diff !== 0) {
          level_price = parseFloat(price).toFixed(0);
        }

        let obj = {
          level: level_price,
          delta: parseFloat(delta).toFixed(4),
          ntd: parseFloat(sums.ntd).toFixed(4),
          perp_d: parseFloat(
            desired_delta - (delta + current_perpetual)
          ).toFixed(4),
          perp_ntd: parseFloat(
            desired_delta - (sums.ntd + current_perpetual)
          ).toFixed(4),
        };

        price_levels.push(obj);
      }
    }
  }
</script>

{#if show_neutralizer_flag}
  <h4 class="mb-025">Greeks</h4>
  <table class="mb-2">
    <thead>
      <tr>
        <th>Instrument</th>
        <th>Size</th>
        <th>IV</th>
        <th>Delta</th>
        <th>Gamma</th>
        <th>Vega</th>
        <th>Theta</th>
      </tr>
    </thead>
    <tbody>
      {#if active_strikes}
        {#each active_strikes as strike}
          {#if "raw" in strike}
            <tr>
              <td>{strike.raw.instrument_name}</td>
              <td>{strike.size}</td>
              <td>{strike.raw.mark_iv}%</td>
              <td
                ><TooltipDefinition
                  direction="top"
                  tooltipText={`${parseFloat(strike.raw.greeks.delta).toFixed(
                    4
                  )}`}
                >
                  {parseFloat(strike.raw.greeks.delta * strike.size).toFixed(4)}
                </TooltipDefinition></td
              >

              <td
                ><TooltipDefinition
                  direction="top"
                  tooltipText={`${parseFloat(strike.raw.greeks.gamma).toFixed(
                    4
                  )}`}
                >
                  {parseFloat(strike.raw.greeks.gamma * strike.size).toFixed(4)}
                </TooltipDefinition></td
              >

              <td
                ><TooltipDefinition
                  direction="top"
                  tooltipText={`${parseFloat(strike.raw.greeks.vega).toFixed(
                    4
                  )}`}
                >
                  {parseFloat(strike.raw.greeks.vega * strike.size).toFixed(4)}
                </TooltipDefinition></td
              >

              <td
                ><TooltipDefinition
                  direction="top"
                  tooltipText={`${parseFloat(strike.raw.greeks.theta).toFixed(
                    4
                  )}`}
                >
                  {parseFloat(strike.raw.greeks.theta * strike.size).toFixed(4)}
                </TooltipDefinition></td
              >
            </tr>
          {/if}
        {/each}
      {/if}
    </tbody>
  </table>

  <h4 class="mb-025">Portfolio neutralizer</h4>
  <div class="numbers mb-025">
    <NumberInput
      min={1}
      max={1000}
      bind:value={price_level_spread}
      size="sm"
      label="Price level spread"
    />

    <NumberInput
      bind:value={current_perpetual}
      size="sm"
      label={`Current perpetual in ${$currency}`}
    />

    <NumberInput bind:value={desired_delta} size="sm" label="Desired Delta" />
  </div>

  <table class="mb-2">
    <thead>
      <tr>
        <th>Price Level</th>
        <th>Portfolio Delta</th>
        <th>NTD</th>
        <th>Perpetual (with Delta)</th>
        <th>Perpetual (with NTD)</th>
      </tr>
    </thead>

    <tbody>
      {#if price_levels}
        {#each price_levels as price_level}
          <tr class:middle={price_level.level === current_price}>
            <td>{price_level.level}</td>
            <td>{price_level.delta}</td>
            <td>{price_level.ntd}</td>
            {#if price_level.level === current_price}
              <td>{current_perpetual}</td>
              <td>{current_perpetual}</td>
            {:else}
              <td>{price_level.perp_d}</td>
              <td>{price_level.perp_ntd}</td>
            {/if}
          </tr>
        {/each}
      {/if}
    </tbody>
  </table>
{/if}

<style>
  .middle {
    background: gold;
  }
  .numbers {
    display: flex;
  }
</style>
