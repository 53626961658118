<script>
  import {
    TickerData,
    BTC_PRICE,
    ETH_PRICE,
    currency,
    custom_scale,
  } from "../store.js";

  import FusionCharts from "fusioncharts";
  import Charts from "fusioncharts/fusioncharts.charts";
  import FusionTheme from "fusioncharts/themes/fusioncharts.theme.umber";
  import SvelteFC, { fcRoot } from "svelte-fusioncharts";

  fcRoot(FusionCharts, Charts, FusionTheme);

  let sorted = [];
  let title = "";
  let sub_title = "";
  let exist;
  let item = false;
  let dataSource = {};
  let mark = [];
  let calls_bid_iv = [];
  let calls_ask_iv = [];
  let put_bid_iv = [];
  let put_ask_iv = [];
  let current_price = 0;

  $: {
    current_price = $currency === "BTC" ? $BTC_PRICE : $ETH_PRICE;
  }

  $: {
    sorted = [...$TickerData.keys()].sort((a, b) => a - b);
  }

  dataSource = {
    chart: {
      caption: title,
      subCaption: sub_title,
      xAxisName: "Strikes",
      yAxisName: "Implied Volatility",
      lineThickness: "1",
      numberSuffix: "%",
      bgColor: "#FFFFFF",
      theme: "umber",
      anchorBorderThickness: "1",
    },
    categories: [{ category: [] }],
    dataset: [
      {
        seriesName: "Mark IV",
        renderAs: "line",
        color: "#1b4bfc",
        plottooltext: "Mark IV — $value%. Open Interest: $displayValue",
        data: mark,
      },
      {
        seriesName: "Call Bid IV",
        renderAs: "line",
        initiallyhidden: 1,
        plottooltext: "Call Bid IV — $value%. Size: $displayValue",
        data: calls_bid_iv,
      },
      {
        seriesName: "Call Ask IV",
        renderAs: "line",
        initiallyhidden: 1,
        plottooltext: "Call Ask IV — $value%. Size: $displayValue",
        data: calls_ask_iv,
      },
      {
        seriesName: "Put Bid IV",
        renderAs: "line",
        initiallyhidden: 1,
        plottooltext: "Put Bid IV — $value%. Size: $displayValue",
        data: put_bid_iv,
      },
      {
        seriesName: "Put Ask IV",
        renderAs: "line",
        initiallyhidden: 1,
        plottooltext: "Put Ask IV — $value%. Size: $displayValue",
        data: put_ask_iv,
      },
    ],
  };

  let chartConfig = {
    type: "mscombi2d",
    width: "100%",
    height: "750",
    dataFormat: "json",
    renderAt: "chart-container",
    dataSource: dataSource,
  };

  $: {
    exist = $TickerData.keys().next().value;
    item =
      exist !== undefined &&
      $TickerData.has(exist) &&
      "CALL" in $TickerData.get(exist);

    title = item
      ? `${$TickerData
          .get(exist)
          .CALL.instrument_name.split("-")
          .slice(0, 2)
          .join(" ")}`
      : "Choose expiration";

    sub_title = item
      ? `${$TickerData.get(exist).CALL.underlying_index} — $${$TickerData
          .get(exist)
          .CALL.underlying_price.toFixed(2)}`
      : "—";

    if (item) {
      mark = sorted.map((itm) => ({
        value:
          "CALL" in $TickerData.get(itm)
            ? $TickerData.get(itm).CALL.mark_iv
            : null,
        anchorRadius:
          "CALL" in $TickerData.get(itm)
            ? $TickerData.get(itm).CALL.open_interest > 0
              ? $TickerData.get(itm).CALL.open_interest / $custom_scale
              : 1
            : 1,
        displayValue:
          "CALL" in $TickerData.get(itm)
            ? $TickerData.get(itm).CALL.open_interest > 0
              ? $TickerData.get(itm).CALL.open_interest
              : "-"
            : "-",
      }));

      calls_bid_iv = sorted.map((itm) => ({
        value:
          "CALL" in $TickerData.get(itm)
            ? $TickerData.get(itm).CALL.bid_iv > 0
              ? $TickerData.get(itm).CALL.bid_iv
              : null
            : null,
        anchorRadius:
          "CALL" in $TickerData.get(itm)
            ? $TickerData.get(itm).CALL.best_bid_amount > 0
              ? $TickerData.get(itm).CALL.best_bid_amount / $custom_scale
              : 1
            : 1,
        displayValue:
          "CALL" in $TickerData.get(itm)
            ? $TickerData.get(itm).CALL.best_bid_amount > 0
              ? $TickerData.get(itm).CALL.best_bid_amount
              : 0
            : 0,
      }));

      calls_ask_iv = sorted.map((itm) => ({
        value:
          "CALL" in $TickerData.get(itm)
            ? $TickerData.get(itm).CALL.ask_iv > 0
              ? $TickerData.get(itm).CALL.ask_iv
              : null
            : null,
        anchorRadius:
          "CALL" in $TickerData.get(itm)
            ? $TickerData.get(itm).CALL.best_ask_amount > 0
              ? $TickerData.get(itm).CALL.best_ask_amount / $custom_scale
              : 1
            : 1,
        displayValue:
          "CALL" in $TickerData.get(itm)
            ? $TickerData.get(itm).CALL.best_ask_amount > 0
              ? $TickerData.get(itm).CALL.best_ask_amount
              : 0
            : 0,
      }));

      put_bid_iv = sorted.map((itm) => ({
        value:
          "PUT" in $TickerData.get(itm)
            ? $TickerData.get(itm).PUT.bid_iv > 0
              ? $TickerData.get(itm).PUT.bid_iv
              : null
            : null,
        anchorRadius:
          "PUT" in $TickerData.get(itm)
            ? $TickerData.get(itm).PUT.best_bid_amount > 0
              ? $TickerData.get(itm).PUT.best_bid_amount / $custom_scale
              : 1
            : 1,
        displayValue:
          "PUT" in $TickerData.get(itm)
            ? $TickerData.get(itm).PUT.best_bid_amount > 0
              ? $TickerData.get(itm).PUT.best_bid_amount
              : 0
            : 0,
      }));

      put_ask_iv = sorted.map((itm) => ({
        value:
          "PUT" in $TickerData.get(itm)
            ? $TickerData.get(itm).PUT.ask_iv > 0
              ? $TickerData.get(itm).PUT.ask_iv
              : null
            : null,
        anchorRadius:
          "PUT" in $TickerData.get(itm)
            ? $TickerData.get(itm).PUT.best_ask_amount > 0
              ? $TickerData.get(itm).PUT.best_ask_amount / $custom_scale
              : 1
            : 1,
        displayValue:
          "PUT" in $TickerData.get(itm)
            ? $TickerData.get(itm).PUT.best_ask_amount > 0
              ? $TickerData.get(itm).PUT.best_ask_amount
              : 0
            : 0,
        open_interest:
          "PUT" in $TickerData.get(itm)
            ? $TickerData.get(itm).PUT.open_interest > 0
              ? $TickerData.get(itm).PUT.open_interest
              : 0
            : 0,
      }));
    }

    dataSource.chart.caption = `Expiration: ${title}`;
    dataSource.chart.subCaption = `Underlying Index: ${sub_title}`;

    dataSource.categories[0].category = sorted.map((i) => ({
      // x: i.toString(),
      label: i.toString(),
    }));

    let founded = -1;

    dataSource.categories[0].category.forEach((itm, index) => {
      if (parseInt(current_price) <= parseInt(itm.label) && founded < 0) {
        founded = index;
      }
    });

    let line_position =
      founded > -1 &&
      dataSource.categories[0].category[founded - 1] !== undefined
        ? (parseInt(current_price) -
            parseInt(dataSource.categories[0].category[founded - 1].label)) /
          (parseInt(dataSource.categories[0].category[founded].label) -
            parseInt(dataSource.categories[0].category[founded - 1].label))
        : 0;

    dataSource.categories[0].category.splice(founded, 0, {
      vLine: "true",
      showOnTop: "1",
      dashed: "1",
      color: "#1b4bfc",
      label: `$${current_price.toString()}`,
      linePosition: line_position,
    });

    dataSource.dataset[0].data = mark;
    dataSource.dataset[1].data = calls_bid_iv;
    dataSource.dataset[2].data = calls_ask_iv;
    dataSource.dataset[3].data = put_bid_iv;
    dataSource.dataset[4].data = put_ask_iv;
    // dataSource.dataset[5].data = call_bid_size;

    chartConfig = {
      ...chartConfig,
      dataSource,
    };
  }

  function labelVisibility(event) {
    let visible = event.detail.data.visible;

    dataSource.dataset[
      event.detail.data.legendItemIndex
    ].initiallyhidden = !visible;

    chartConfig = {
      ...chartConfig,
      dataSource,
    };
  }
</script>

<h4>Deribit Volatility Smile</h4>
<div>
  <SvelteFC
    {...chartConfig}
    on:legendItemClicked={labelVisibility}
    id="chart-container"
  />
</div>

<style>
  h4 {
    padding-left: 63px;
  }
</style>
