<script>
  import {
    TickerData,
    custom_price,
    selectedInstrumentsList,
    table_from,
    table_to,
  } from "../store.js";
  import { Select, SelectItem } from "carbon-components-svelte";
  import Row from "./Row.svelte";

  let sorted = [];
  let title = "";
  let exist;
  let item = false;
  let instrument_strikes = [];

  $: {
    sorted = [...$TickerData.keys()];
    sorted.sort((a, b) => a - b);
  }

  $: {
    exist = $TickerData.keys().next().value;
    item =
      exist !== undefined &&
      $TickerData.has(exist) &&
      "CALL" in $TickerData.get(exist);

    title = item
      ? `${$TickerData
          .get(exist)
          .CALL.instrument_name.split("-")
          .slice(0, 2)
          .join(" ")} — $${$TickerData
          .get(exist)
          .CALL.underlying_price.toFixed(2)}`
      : "Choose expiration";
  }

  function setCustomPrice() {
    $custom_price = item ? $TickerData.get(exist).CALL.underlying_price : 9999;
  }

  $: {
    $selectedInstrumentsList.then((list) => {
      instrument_strikes = [
        ...new Set(list.map((x) => x.split("-")[2]).sort((a, b) => a - b)),
      ];
    });
  }
</script>

<style>
  .centered {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .selects {
    display: flex;
  }
</style>

<div class="table">
  <div class="centered mb-05">
    <h4 class="mb-025 prc" on:click={setCustomPrice}>{title}</h4>
    <div class="selects">
      <Select
        inline
        light
        labelText="From:"
        bind:selected={$table_from}
        class="mr-1">
        <SelectItem value={0} text="Select strike" selected />
        {#each instrument_strikes as strike}
          <SelectItem value={strike} text={strike} />
        {/each}
      </Select>

      <Select inline light labelText="To:" bind:selected={$table_to}>
        <SelectItem value={0} text="Select strike" selected />
        {#each instrument_strikes as strike}
          <SelectItem value={strike} text={strike} />
        {/each}
      </Select>
    </div>
  </div>
  <table>
    <thead>
      <tr>
        <th>Bid</th>
        <th>Mark Price</th>
        <th>Ask</th>
        <th>Greeks</th>
        <th><b>Strike</b></th>
        <th>Bid</th>
        <th>Mark Price</th>
        <th>Ask</th>
        <th>Greeks</th>
      </tr>
    </thead>
    <tbody>
      {#each sorted as strike}
        {#if $TickerData.has(strike) && strike >= $table_from && strike <= $table_to}
          <Row data={$TickerData.get(strike)} {strike} />
        {/if}
      {/each}
    </tbody>
  </table>
</div>
