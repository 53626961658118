<script>
  import dayjs from "dayjs";
  import { blackScholes } from "../bs/bs.js";
  import {
    getDelta,
    getVega,
    getGamma,
    getTheta,
    getRho,
  } from "../bs/greeks.js";
  import { BTC_PRICE, ETH_PRICE, custom_vol, custom_price } from "../store.js";

  export let option_type;
  export let data;
  let custom_delta = 0;
  let custom_gamma = 0;
  let custom_vega = 0;
  let custom_theta = 0;
  let custom_rho = 0;
  let custom_bs = 0;
  let custom_iv = 0;
  let index_price = 0;
  $: {
    index_price =
      data.instrument_name.split("-")[0] === "BTC" ? $BTC_PRICE : $ETH_PRICE;
  }
  // s - Current price of the underlying
  // k - Strike price
  // t - Time to expiration in years
  // v - Volatility as a decimal
  // r - Annual risk-free interest rate as a decimal
  // callPut - The type of option to be priced - "call" or "put"
  // [scale] - The value to scale a return value by
  let s, k, t, v, r;

  $: {
    let now = dayjs();
    let expiration = data.instrument_name.split("-")[1];
    let exp = `${expiration.match(/\d+/g)[0]} ${
      expiration.match(/[A-Z]+/)[0]
    } 20${expiration.match(/\d+/g)[1]} 11:00:00`;

    s = $custom_price;
    k = parseFloat(data.instrument_name.split("-")[2]);
    t = dayjs(exp).diff(now, "y", true);
    v = $custom_vol / 100;
    r = 0;

    custom_delta = getDelta(s, k, t, v, r, option_type).toFixed(2);
    custom_gamma = getGamma(s, k, t, v, r).toFixed(2);
    custom_vega = getVega(s, k, t, v, r).toFixed(2);
    custom_theta = getTheta(s, k, t, v, r, option_type).toFixed(2);
    custom_rho = getRho(s, k, t, v, r, option_type).toFixed(2);
    custom_bs = blackScholes(s, k, t, v, r, option_type).toFixed(2);
    // custom_iv =
    //   getImpliedVolatility(custom_bs, s, k, t, r, option_type).toFixed(2) * 100;
  }

  function setCustomVol(vol) {
    $custom_vol = vol;
  }
</script>

<td>
  <p class="green" title="Best Bid Price">{data.best_bid_price.toFixed(4)}</p>
  <small title="Best Bid Price USD">
    {`$${(data.underlying_price * data.best_bid_price).toFixed(2)}`}
  </small>
  <small title="Bid IV" class="imv" on:click={() => setCustomVol(data.bid_iv)}>
    {data.bid_iv}%
  </small>
  <small title="Best Bid Amount">{`${data.best_bid_amount.toFixed(1)}`}</small>
</td>

<td>
  <span class="row_block">
    <p title="Mark Price">{data.mark_price.toFixed(4)}</p>
    <p class="blue"><b>{(custom_bs / $custom_price).toFixed(4)}</b></p>
  </span>
  <span class="row_block">
    <small>{`$${(data.underlying_price * data.mark_price).toFixed(2)}`}</small>
    <small class="blue"> <b>${custom_bs}</b> </small>
  </span>
  <small class="imv" on:click={() => setCustomVol(data.mark_iv)}>
    {data.mark_iv}%
  </small>
  <small title="Open Interest">
    OI:
    {`${data.open_interest.toFixed(1)}`}
  </small>
  {#if data.stats.volume !== null}
    <small title="Volume"> V: {`${data.stats.volume.toFixed(1)}`}</small>
  {/if}
</td>

<td>
  <p class="red" title="Best Ask Price">{data.best_ask_price.toFixed(4)}</p>
  <small title="Best Ask Price USD">
    {`$${(data.underlying_price * data.best_ask_price).toFixed(2)}`}
  </small>
  <small title="Ask IV" class="imv" on:click={() => setCustomVol(data.ask_iv)}>
    {`${data.ask_iv}%`}
  </small>
  <small title="Best Ask Amount">{`${data.best_ask_amount.toFixed(1)}`}</small>
</td>
<td class="td_wrapper">
  <small>
    <p title="Net Transaction Delta">
      <span class="td_name">NTD</span>
      <span class="td_block"
        >{(data.greeks.delta - data.mark_price).toFixed(2)}</span
      >
      <span class="td_block blue"
        >{(custom_delta - custom_bs / $custom_price).toFixed(2)}</span
      >
    </p>
    <p title="Delta">
      <span class="td_name">Δ</span>
      <span class="td_block">{data.greeks.delta.toFixed(2)}</span>
      <span class="td_block blue">{custom_delta}</span>
    </p>
    <p title="Gamma">
      <span class="td_name">γ</span>
      <span class="td_block">{data.greeks.gamma.toFixed(2)}</span>
      <span class="td_block blue">{custom_gamma}</span>
    </p>
    <p title="Vega">
      <span class="td_name">ν</span>
      <span class="td_block">{data.greeks.vega.toFixed(2)}</span>
      <span class="td_block blue">{custom_vega}</span>
    </p>
    <p title="Theta">
      <span class="td_name">θ</span>
      <span class="td_block">{data.greeks.theta.toFixed(2)}</span>
      <span class="td_block blue">{custom_theta}</span>
    </p>
    <p title="Rho">
      <span class="td_name">p</span>
      <span class="td_block">{data.greeks.rho.toFixed(2)}</span>
      <span class="td_block blue">{custom_rho}</span>
    </p>
  </small>
</td>
