<script>
  import dayjs from "dayjs";
  import customParseFormat from "dayjs/plugin/customParseFormat";
  import { blackScholes } from "../bs/bs.js";
  import {
    ButtonSet,
    Button,
    NumberInput,
    DatePicker,
    DatePickerInput,
    DataTable,
  } from "carbon-components-svelte";

  dayjs.extend(customParseFormat);

  let mark_price = 100;
  let strike_price = 10000;
  let expiration = dayjs().format("DD/MM/YYYY");
  let results = [];

  function calculate_iv() {
    results = [];

    let now = dayjs();
    let ex = dayjs(expiration, "DD/MM/YYYY").add(12, "hours");
    let t = dayjs(ex).diff(now, "y", true);

    for (let volatility = 0; volatility <= 150; volatility++) {
      if (volatility % 5 === 0) {
        results.push({
          id: volatility,
          CALL: getPriceByVol(volatility, mark_price, strike_price, t, "call"),
          IV: volatility,
          PUT: getPriceByVol(volatility, mark_price, strike_price, t, "put"),
        });
      }
    }
  }

  function getPriceByVol(
    vol,
    expected_price,
    strike_price,
    expiration,
    option_type
  ) {
    expected_price = parseFloat(expected_price) || 0;
    let result = 0;

    for (let price = 1; price < 20000; price++) {
      let bs = blackScholes(
        price,
        parseFloat(strike_price),
        expiration,
        vol,
        0,
        option_type
      ).toFixed(0);

      if (bs == expected_price.toFixed(0)) {
        result = price;
        break;
      }
    }

    return result;
  }
</script>

<main class="main">
  <div class="main_left">
    <NumberInput
      class="mb-1"
      size="sm"
      type="number"
      min={0}
      step={1}
      label="Mark Price, $"
      bind:value={mark_price} />

    <NumberInput
      class="mb-1"
      size="sm"
      type="number"
      min={0}
      step={1}
      label="Strike"
      bind:value={strike_price} />

    <DatePicker
      class="mb-1"
      datePickerType="single"
      dateFormat="d/m/Y"
      bind:value={expiration}>
      <DatePickerInput
        size="sm"
        labelText="Expiration"
        placeholder="dd/mm/yyyy" />
    </DatePicker>
    <ButtonSet>
      <Button size="small" kind="tertiary" on:click={calculate_iv}>
        Calculate IV
      </Button>
    </ButtonSet>
  </div>

  <div class="main_right">
    <DataTable
      size="short"
      headers={[{ key: 'CALL', value: 'CALL' }, { key: 'IV', value: 'IV' }, { key: 'PUT', value: 'PUT' }]}
      rows={results} />
  </div>
</main>
