export const routes = [
  {
    text: "Custom Options Table",
    url: "options",
  },
  {
    text: "IV + Price Calculations",
    url: "calculations",
  },
  {
    text: "Volatility Smile",
    url: "smile",
  },
  {
    text: "Hedge Visualization",
    url: "hedge",
  },
];
