<script>
  import {
    ButtonSet,
    Button,
    Select,
    SelectItem,
    SelectItemGroup,
    Modal,
    TextArea,
    DataTable,
    TooltipDefinition,
    NumberInput,
    CodeSnippet,
    Toolbar,
    ToolbarContent,
    ToolbarMenu,
    ToolbarMenuItem,
    Form,
    FormGroup,
    Checkbox,
    Row,
    Column,
  } from "carbon-components-svelte";

  import copy from "clipboard-copy";
  import CloudDownload16 from "carbon-icons-svelte/lib/CloudDownload16";
  import CloudUpload16 from "carbon-icons-svelte/lib/CloudUpload16";
  import ChartLineData16 from "carbon-icons-svelte/lib/ChartLineData16";
  import Edit16 from "carbon-icons-svelte/lib/Edit16";
  import Subtract16 from "carbon-icons-svelte/lib/Subtract16";
  import Add16 from "carbon-icons-svelte/lib/Add16";
  import Delete16 from "carbon-icons-svelte/lib/Delete16";
  import ChartHistogram16 from "carbon-icons-svelte/lib/ChartHistogram16";

  import Visualization from "./../Components/Hedge/Visualization.svelte";
  import Neutralizer from "../Components/Hedge/Neutralizer.svelte";
  import Currencies from "./../Components/Currencies.svelte";

  import {
    instruments,
    expirations,
    RPCSubscribe,
    RPCUnsubscribe,
    RAWTickerData,
    currency,
    BTC_PRICE,
    ETH_PRICE,
  } from "../store.js";
  import { selected_expiration } from "../Stores/hedge";

  let selected_strike = "";
  let current_price = "";
  let instrument_list = [];
  let active_strikes = [];
  let textarea_parse = "";
  let parser_modal = false;
  let ticker_edit_modal = false;
  let dh_config_modal = false;
  let dh_config_code = "";
  let editable_ticker_header = "Edit ...";
  let viz;
  let neutral;

  $: {
    current_price = parseFloat(
      ($currency === "BTC" ? $BTC_PRICE : $ETH_PRICE).toFixed(2)
    );
  }

  function set_active_expiration(expiration) {
    $selected_expiration = expiration;
    selected_strike = "";
    $instruments.then((instruments) => {
      instrument_list = instruments
        .filter((instrument) =>
          instrument.instrument_name.includes($selected_expiration)
        )
        .sort((a, b) => {
          if (parseFloat(a.strike) > parseFloat(b.strike)) {
            return 1;
          }
          if (parseFloat(a.strike) < parseFloat(b.strike)) {
            return -1;
          }
          return 0;
        });
    });
  }

  function add_strike(strike, buy_price = 0, size = 1) {
    if (active_strikes.filter((item) => item.ticker === strike).length === 0) {
      active_strikes = [
        ...active_strikes,
        {
          min_index_price: 0,
          max_index_price: 999999,
          reversed_condition: false,
          exclude_option_premium: false,
          use_net_transaction_delta: false,
          delta_multiplier: 1.0,
          premium_multiplier: 1.0,
          id: strike,
          ticker: strike,
          active_delta: 0,
          mark_price: 0,
          mark_iv: 0,
          delta: 0,
          ntd: 0,
          buy_price: parseFloat(buy_price),
          size: parseFloat(size),
        },
      ];

      RPCSubscribe([`ticker.${strike}.100ms`]);
    }
  }

  $: {
    active_strikes.forEach((item) => {
      if ($RAWTickerData.hasOwnProperty(item.ticker)) {
        // Raw
        item["raw"] = $RAWTickerData[item.ticker];
        // — // — // — // — //

        item["mark_price"] = $RAWTickerData[item.ticker].mark_price;
        item["mark_iv"] = $RAWTickerData[item.ticker].mark_iv;
        item["underlying_price"] = $RAWTickerData[item.ticker].underlying_price;
        item["delta"] = $RAWTickerData[item.ticker].greeks.delta;
        item["ntd"] =
          $RAWTickerData[item.ticker].greeks.delta -
          $RAWTickerData[item.ticker].mark_price;
      }
    });

    for (let index = 0; index < active_strikes.length; index++) {
      const active_ticker = active_strikes[index];
      active_ticker["active_delta"] = calculate_ticker_dh_delta(active_ticker);
    }

    active_strikes = active_strikes;
  }

  function remove_strike(strike) {
    let ticker_index = active_strikes.findIndex((i) => i.ticker === strike);
    active_strikes.splice(ticker_index, 1);
    active_strikes = active_strikes;
    RPCUnsubscribe([`ticker.${strike}.100ms`]);
  }

  function remove_all_strikes() {
    RPCUnsubscribe(active_strikes.map((i) => `ticker.${i.ticker}.100ms`));
    active_strikes = [];
  }

  function parse_strikes() {
    let items = textarea_parse.trim().split("\n");
    items.forEach((strike) => {
      let ticker = strike.split("	")[0];
      let buy_price = strike.split("	")[4];
      let size = strike.includes("BTC")
        ? parseFloat(strike.split("BTC	")[1])
        : parseFloat(strike.split("ETH	")[1]);

      add_strike(ticker, buy_price, size);
    });
    parser_modal = false;
  }
  let editable_ticker_temp_data = {};

  function edit_ticker(strike) {
    let ticker_index = active_strikes.findIndex((i) => i.ticker === strike);
    editable_ticker_header = `Edit ${strike}`;
    editable_ticker_temp_data = active_strikes[ticker_index];
    ticker_edit_modal = true;
  }

  function add_size_to_ticker(strike) {
    let ticker_index = active_strikes.findIndex((i) => i.ticker === strike);
    active_strikes[ticker_index]["size"] =
      parseFloat(active_strikes[ticker_index]["size"]) + 1;
  }

  function subtract_size_from_ticker(strike) {
    let ticker_index = active_strikes.findIndex((i) => i.ticker === strike);
    active_strikes[ticker_index]["size"] =
      parseFloat(active_strikes[ticker_index]["size"]) - 1;
  }

  function update_ticker() {
    let ticker_index = active_strikes.findIndex(
      (i) => i.ticker === editable_ticker_temp_data.ticker
    );
    active_strikes[ticker_index] = editable_ticker_temp_data;
    active_strikes = active_strikes;
    ticker_edit_modal = false;
  }

  function save_tickers() {
    localStorage.setItem("hedge_store", JSON.stringify(active_strikes));
  }

  function load_tickers() {
    active_strikes = JSON.parse(localStorage.getItem("hedge_store"));
    RPCSubscribe(active_strikes.map((i) => `ticker.${i.ticker}.100ms`));
  }

  function calculate_ticker_dh_delta(active_ticker) {
    if (
      (!active_ticker.reversed_condition &&
        current_price >= active_ticker.min_index_price &&
        current_price <= active_ticker.max_index_price) ||
      (active_ticker.reversed_condition &&
        (current_price <= active_ticker.min_index_price ||
          current_price >= active_ticker.max_index_price))
    ) {
      if (active_ticker.use_net_transaction_delta) {
        return (
          (active_ticker.delta - active_ticker.mark_price) *
          active_ticker.size *
          active_ticker.delta_multiplier
        );
      } else {
        return (
          active_ticker.delta *
          active_ticker.size *
          active_ticker.delta_multiplier
        );
      }
    } else {
      return 0;
    }
  }

  function build_config() {
    dh_config_code = `  hedged_tickers: >
    [
      ${active_strikes
        .map(
          (strike) => `HedgedTicker(
        ticker='${strike.ticker}',
        min_index_price=Decimal('${parseFloat(strike.min_index_price).toFixed(
          2
        )}'),
        max_index_price=Decimal('${parseFloat(strike.max_index_price).toFixed(
          2
        )}'),
        reversed_condition=${strike.reversed_condition ? "True" : "False"},
        exclude_option_premium=False,
        use_net_transaction_delta=${
          strike.use_net_transaction_delta ? "True" : "False"
        },
        delta_multiplier=Decimal('${parseFloat(strike.delta_multiplier).toFixed(
          2
        )}'),
        premium_multiplier=Decimal('1.0'),
      ),`
        )
        .join("\n      ")}
    ]`;
  }
</script>

<main class="main">
  <div class="main_left">
    <Currencies />
    <div class="mb-1">
      <Modal
        bind:open={parser_modal}
        modalHeading="Parse strikes"
        primaryButtonText="Parse"
        secondaryButtonText="Cancel"
        on:click:button--secondary={() => (parser_modal = false)}
        on:open
        on:close
        on:submit={parse_strikes}
      >
        <TextArea
          bind:value={textarea_parse}
          rows={10}
          placeholder="Paste strikes from portfolio manager"
        />
      </Modal>
      <Modal
        bind:open={dh_config_modal}
        modalHeading="Delta Hedger config"
        primaryButtonText="Copy and close"
        secondaryButtonText="Cancel"
        hasScrollingContent={true}
        on:click:button--secondary={() => (dh_config_modal = false)}
        on:open={() => build_config()}
        on:close
        on:submit={() => {
          copy(dh_config_code);
          dh_config_modal = false;
        }}
      >
        <CodeSnippet
          type="multi"
          showMoreLess={true}
          on:click={() => copy(dh_config_code)}
          code={dh_config_code}
        />
      </Modal>
    </div>
    <div>
      <h4 class="mb-025">Expirations</h4>
      <ButtonSet stacked class="mb-1">
        {#await $expirations}
          <Button skeleton size="field" />
          <Button skeleton size="field" />
          <Button skeleton size="field" />
          <Button skeleton size="field" />
          <Button skeleton size="field" />
          <Button skeleton size="field" />
          <Button skeleton size="field" />
          <Button skeleton size="field" />
          <Button skeleton size="field" />
          <Button skeleton size="field" />
        {:then expirations}
          {#each expirations as expiration}
            <Button
              size="field"
              kind={expiration == $selected_expiration ? "primary" : "ghost"}
              on:click={() => set_active_expiration(expiration)}
            >
              {expiration}
            </Button>
          {/each}
        {/await}
      </ButtonSet>
      {#if $selected_expiration !== ""}
        <Select
          labelText="Select ticker"
          size="sm"
          bind:selected={selected_strike}
          class="mb-1"
        >
          <SelectItem value="0" text="Select strike" disabled hidden />
          <SelectItemGroup label="Call">
            {#each instrument_list as instrument}
              {#if instrument.option_type.toLowerCase() == "call"}
                <SelectItem
                  value={instrument.instrument_name}
                  text={instrument.instrument_name}
                />
              {/if}
            {/each}
          </SelectItemGroup>
          <SelectItemGroup label="Put">
            {#each instrument_list as instrument}
              {#if instrument.option_type.toLowerCase() == "put"}
                <SelectItem
                  value={instrument.instrument_name}
                  text={instrument.instrument_name}
                />
              {/if}
            {/each}
          </SelectItemGroup>
        </Select>
        <ButtonSet stacked>
          <Button
            size="small"
            disabled={selected_strike === ""}
            on:click={() => add_strike(selected_strike)}>Add ticker</Button
          >
        </ButtonSet>
      {/if}
    </div>
  </div>
  <div class="main_right">
    <h4 class="mb-025">Active tickers</h4>
    <DataTable
      class="mb-2"
      headers={[
        { key: "ticker", value: "Ticker" },
        { key: "active_delta", value: "DH Δ" },
        { key: "mark_price", value: "Mark Price" },
        { key: "mark_iv", value: "Mark IV" },
        { key: "delta", value: "Delta" },
        { key: "ntd", value: "NTD" },
        { key: "size", value: "Size" },
        // { key: "buy_price", value: "Buy Price" },
        { key: "overflow", value: "Options" },
      ]}
      rows={active_strikes}
    >
      <span slot="cell-header" let:header>
        {#if header.key === "active_delta"}
          {header.value}
          {#if active_strikes.length}
            ({parseFloat(
              active_strikes.reduce((a, b) => a + b["active_delta"], 0)
            ).toFixed(2)})
          {/if}
        {:else if header.key === "delta"}
          {header.value}
          {#if active_strikes.length}
            ({parseFloat(
              active_strikes.reduce((a, b) => a + b["delta"] * b["size"], 0)
            ).toFixed(2)})
          {/if}
        {:else if header.key === "ntd"}
          {header.value}
          {#if active_strikes.length}
            ({parseFloat(
              active_strikes.reduce((a, b) => a + b["ntd"] * b["size"], 0)
            ).toFixed(2)})
          {/if}
        {:else}{header.value}{/if}
      </span>

      <span slot="cell" let:row let:cell>
        {#if cell.key === "active_delta"}
          <strong> {parseFloat(cell.value).toFixed(4)} </strong>
        {:else if cell.key === "mark_price"}
          <TooltipDefinition
            direction="top"
            tooltipText={`$${parseFloat(cell.value * current_price).toFixed(
              2
            )}`}
          >
            {parseFloat(cell.value).toFixed(4)}
          </TooltipDefinition>
        {:else if cell.key === "mark_iv"}
          {cell.value}%
        {:else if cell.key === "delta"}
          <TooltipDefinition
            direction="top"
            tooltipText={parseFloat(cell.value).toFixed(4)}
          >
            {parseFloat(cell.value * row.size).toFixed(4)}
          </TooltipDefinition>
        {:else if cell.key === "ntd"}
          <TooltipDefinition
            direction="top"
            tooltipText={parseFloat(cell.value).toFixed(4)}
          >
            {parseFloat(cell.value * row.size).toFixed(4)}
          </TooltipDefinition>
        {:else if cell.key === "overflow"}
          <Button
            size="small"
            kind="tertiary"
            hasIconOnly
            tooltipPosition="top"
            tooltipAlignment="center"
            iconDescription="Subtract size"
            icon={Subtract16}
            on:click={() => subtract_size_from_ticker(row.ticker)}
          />

          <Button
            size="small"
            kind="tertiary"
            hasIconOnly
            tooltipPosition="top"
            tooltipAlignment="center"
            iconDescription="Add size"
            icon={Add16}
            on:click={() => add_size_to_ticker(row.ticker)}
          />

          <Button
            size="small"
            kind="tertiary"
            hasIconOnly
            tooltipPosition="top"
            tooltipAlignment="center"
            iconDescription="Edit"
            icon={Edit16}
            on:click={() => edit_ticker(row.ticker)}
          />

          <Button
            size="small"
            kind="danger-tertiary"
            hasIconOnly
            tooltipPosition="top"
            tooltipAlignment="center"
            iconDescription="Delete"
            icon={Delete16}
            on:click={() => remove_strike(row.ticker)}
          />
        {:else}{cell.value}{/if}
      </span>

      <Toolbar size="sm">
        <ToolbarContent>
          <ToolbarMenu>
            <ToolbarMenuItem on:click={() => (parser_modal = true)}>
              Open PM parser
            </ToolbarMenuItem>
            <ToolbarMenuItem on:click={() => (dh_config_modal = true)}>
              Show DH config
            </ToolbarMenuItem>
            <ToolbarMenuItem
              danger
              on:click={() => remove_all_strikes()}
              disabled={!active_strikes.length}
              >Delete all tickers</ToolbarMenuItem
            >
          </ToolbarMenu>
          <Button
            size="small"
            icon={CloudDownload16}
            disabled={!localStorage.hasOwnProperty("hedge_store")}
            on:click={() => load_tickers()}>Load</Button
          >
          <Button
            size="small"
            icon={CloudUpload16}
            disabled={!active_strikes.length}
            on:click={() => save_tickers()}>Save</Button
          >
          <Button
            size="small"
            icon={ChartHistogram16}
            disabled={!active_strikes.length}
            on:click={() => neutral.show_neutralizer()}>Neutralizer</Button
          >
          <Button
            size="small"
            icon={ChartLineData16}
            disabled={!active_strikes.length}
            on:click={() => viz.show_visualization()}>Visualization</Button
          >
        </ToolbarContent>
      </Toolbar>
    </DataTable>

    <Neutralizer {active_strikes} bind:this={neutral} />
    <Visualization {current_price} {active_strikes} bind:this={viz} />
  </div>

  <Modal
    bind:open={ticker_edit_modal}
    modalHeading={editable_ticker_header}
    primaryButtonText="Update"
    secondaryButtonText="Cancel"
    hasForm
    preventCloseOnClickOutside
    on:click:button--secondary={() => (ticker_edit_modal = false)}
    on:open
    on:close
    on:submit={() => update_ticker()}
  >
    <Form on:submit>
      <Row>
        <Column>
          <NumberInput
            class="mb-1"
            label="Size"
            step={0.1}
            type="number"
            bind:value={editable_ticker_temp_data["size"]}
          />
          <NumberInput
            class="mb-1"
            step={1}
            min={0}
            label="Min Index Price"
            type="number"
            bind:value={editable_ticker_temp_data["min_index_price"]}
          />

          <FormGroup>
            <Checkbox
              labelText="Reversed condition"
              bind:checked={editable_ticker_temp_data["reversed_condition"]}
            />
            <!-- <Checkbox
              labelText="Exclude Option Premium"
              bind:checked={editable_ticker_temp_data['exclude_option_premium']} /> -->
            <Checkbox
              labelText="Use Net Transaction Delta"
              bind:checked={editable_ticker_temp_data[
                "use_net_transaction_delta"
              ]}
            />
          </FormGroup>
        </Column>
        <Column>
          <NumberInput
            class="mb-1"
            step={0.1}
            min={0}
            label="Delta multiplier"
            type="number"
            bind:value={editable_ticker_temp_data["delta_multiplier"]}
          />
          <!-- <NumberInput
            step={0.1}
            min={0}
            class="mb-1"
            label="Buy price"
            type="number"
            bind:value={editable_ticker_temp_data['buy_price']} /> -->
          <NumberInput
            class="mb-1"
            step={1}
            min={0}
            label="Max Index Price"
            type="number"
            bind:value={editable_ticker_temp_data["max_index_price"]}
          />
          <!-- <NumberInput
            class="mb-1"
            step={0.1}
            min={0}
            label="Premium multiplier"
            type="number"
            bind:value={editable_ticker_temp_data['premium_multiplier']} /> -->
        </Column>
      </Row>
    </Form>
  </Modal>
</main>
