<script>
  import FusionCharts from "fusioncharts";
  import Charts from "fusioncharts/fusioncharts.charts";
  import FusionTheme from "fusioncharts/themes/fusioncharts.theme.umber";
  import SvelteFC, { fcRoot } from "svelte-fusioncharts";
  import { delta_calculation } from "./helpers";
  fcRoot(FusionCharts, Charts, FusionTheme);

  export let current_price = 0;
  export let active_strikes = [];

  let dataSource = {};
  let show_viz = false;

  dataSource = {
    chart: {
      xAxisName: "Price",
      yAxisName: "Delta",
      lineThickness: 0.5,
      bgColor: "#FFFFFF",
      theme: "umber",
      anchorBorderThickness: 0.5,
    },
    categories: [{ category: [] }],
    dataset: [
      {
        seriesName: "Delta",
        renderAs: "line",
        color: "#1b4bfc",
        plottooltext: "Delta: $value @ $displayValue",
        data: [],
      },
      {
        seriesName: "Delta + Smile",
        renderAs: "line",
        initiallyhidden: 1,
        plottooltext: "Delta + Smile: $value @ $displayValue",
        data: [],
      },
      {
        seriesName: "NTD",
        renderAs: "line",
        plottooltext: "NTD:   $value @ $displayValue",
        data: [],
      },
      {
        seriesName: "NTD + Smile",
        renderAs: "line",
        initiallyhidden: 1,
        plottooltext: "NTD + Smile: $value @ $displayValue",
        data: [],
      },
    ],
  };

  let chartConfig = {
    type: "mscombi2d",
    width: "100%",
    height: "750",
    dataFormat: "json",
    renderAt: "chart-container",
    dataSource: dataSource,
  };

  export function show_visualization() {
    let PRICE_RANGE = current_price * 2;
    let PRICE_DIFF = 100;
    dataSource.categories[0].category = [];

    let results = [];

    for (
      let custom_price = 0;
      custom_price <= PRICE_RANGE;
      custom_price += PRICE_DIFF
    ) {
      show_viz = true;
      dataSource.categories[0].category.push({
        label: custom_price.toString(),
      });

      let sums = {
        delta: 0,
        ntd: 0,
      };

      active_strikes.forEach((element) => {
        let dh = delta_calculation(custom_price, element);
        sums.delta = sums.delta + dh.delta;
        sums.ntd = sums.ntd + dh.ntd;
      });

      results.push({
        delta: sums.delta,
        ntd: sums.ntd,
        label: custom_price,
      });
    }

    // Delta
    dataSource.dataset[0].data = results.map((itm) => ({
      value: parseFloat(itm.delta.toFixed(4)),
      anchorRadius: 1,
      displayValue: itm.label,
    }));

    // Delta + Smile
    // dataSource.dataset[1].data = results.map((itm) => ({
    //   value: parseFloat(itm.delta.toFixed(4)),
    //   anchorRadius: 1,
    //   displayValue: itm.label,
    // }));

    // NTD + Smile
    dataSource.dataset[2].data = results.map((itm) => ({
      value: parseFloat(itm.ntd.toFixed(4)),
      anchorRadius: 1,
      displayValue: itm.label,
    }));

    // NTD + Smile
    // dataSource.dataset[3].data = results.map((itm) => ({
    //   value: parseFloat(itm.ntd.toFixed(4)),
    //   anchorRadius: 1,
    //   displayValue: itm.label,
    // }));

    chartConfig = {
      ...chartConfig,
      dataSource,
    };
  }

  function labelVisibility(event) {
    let visible = event.detail.data.visible;

    dataSource.dataset[
      event.detail.data.legendItemIndex
    ].initiallyhidden = !visible;

    chartConfig = {
      ...chartConfig,
      dataSource,
    };
  }
</script>

{#if show_viz}
  <div>
    <SvelteFC
      {...chartConfig}
      on:legendItemClicked={labelVisibility}
      id="chart-container"
    />
  </div>
{/if}
