<script>
  import { ToastNotification } from "carbon-components-svelte";
  import { notifications } from "../Stores/notifications";

  function remove_notification_by_id(id) {
    $notifications.splice(id, 1);
    $notifications = [...$notifications];
  }
</script>

<div class="notifications">
  {#each $notifications as notification, i}
    <ToastNotification
      kind={notification.kind}
      timeout={5000}
      lowContrast
      title={notification.title}
      subtitle={notification.subtitle}
      caption={notification.caption}
      on:close={() => remove_notification_by_id(i)}
    />
  {/each}
</div>

<style>
  .notifications {
    position: fixed;
    bottom: 0;
    right: 0;
  }
</style>
