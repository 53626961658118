<script>
  import dayjs from "dayjs";

  import { ButtonSet, Button } from "carbon-components-svelte";

  import {
    expirations,
    RPCUnsubscribe,
    selectedExpiration,
    selectedInstrumentsList,
    currency,
    table_from,
    table_to,
  } from "../store.js";

  const formatExpiration = (expiration) => {
    let exp = dayjs(
      `${expiration.match(/\d+/g)[0]} ${expiration.match(/[A-Z]+/)[0]} 20${
        expiration.match(/\d+/g)[1]
      }`
    );
    return `${exp.format("DD MMM YYYY")}`;
  };

  async function setExpiration(expiration) {
    $selectedInstrumentsList.then((list) => {
      if (list.length > 0) {
        RPCUnsubscribe(list.map((i) => `ticker.${i}.100ms`));
      }
    });

    $table_from = 0;
    $table_to = 1000000;
    $selectedExpiration = expiration;
  }
</script>

<div>
  <h4 class="mb-025">Expirations {$currency}</h4>
  <ButtonSet stacked>
    {#await $expirations}
      <Button skeleton size="field" />
      <Button skeleton size="field" />
      <Button skeleton size="field" />
      <Button skeleton size="field" />
      <Button skeleton size="field" />
      <Button skeleton size="field" />
      <Button skeleton size="field" />
      <Button skeleton size="field" />
      <Button skeleton size="field" />
      <Button skeleton size="field" />
    {:then expirations}
      {#each expirations as expiration}
        <Button
          size="field"
          kind={expiration == $selectedExpiration ? 'primary' : 'ghost'}
          on:click={() => setExpiration(expiration)}>
          {formatExpiration(expiration)}
        </Button>
      {/each}
    {/await}
  </ButtonSet>
</div>
