<script>
  import { Link } from "carbon-components-svelte";
  import { routes } from "../stores/routes.js";
</script>

<nav>
  {#each routes as link}
    <p>
      <Link size="lg" href={`/#/${link.url}`}>{link.text}</Link>
    </p>
  {/each}
</nav>
