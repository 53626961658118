<script>
  import { ButtonSet, Button } from "carbon-components-svelte";

  import { onMount } from "svelte";
  import {
    currency,
    BTC_PRICE,
    ETH_PRICE,
    RPCSubscribe,
    RPCUnsubscribe,
    selectedInstrumentsList,
  } from "../store.js";

  import { selected_expiration } from "../Stores/hedge";

  onMount(async () => {
    await RPCSubscribe([
      "deribit_price_index.btc_usd",
      "deribit_price_index.eth_usd",
    ]);
  });

  async function setCurrency(cur) {
    $selectedInstrumentsList.then((list) => {
      if (list.length > 0) {
        RPCUnsubscribe(list.map((i) => `ticker.${i}.100ms`));
      }
    });

    $currency = cur;
    $selected_expiration = "";
  }
</script>

<div class="mb-1">
  <h4 class="mb-025">Currency</h4>
  <ButtonSet stacked>
    {#each ['BTC', 'ETH'] as currency_name}
      <Button
        size="small"
        kind={currency_name === $currency ? 'primary' : 'tertiary'}
        on:click={() => setCurrency(currency_name)}>
        <span> {currency_name} </span>
        <span>
          {(currency_name === 'BTC' ? $BTC_PRICE : $ETH_PRICE).toFixed(2)}
        </span>
      </Button>
    {/each}
  </ButtonSet>
</div>
