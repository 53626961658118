<script>
  import Option from './OptionData.svelte';
  export let strike;
  export let data = {};
</script>

{#if (data !== undefined && 'CALL' in data)}
  <tr>
    {#if ('CALL' in data)}
      <Option data={data.CALL} option_type="call"/>
    {:else}
      <td colspan="4">
    {/if}
    <td>
      <b>{strike}</b>
    </td>
    {#if ("PUT" in data)}
      <Option data={data.PUT} option_type="put"/>
    {:else}
      <td colspan="4">
    {/if}
  </tr>
{/if}