import { writable } from "svelte/store";
import { derived } from "svelte/store";
import {
  RequestManager,
  Client,
  WebSocketTransport,
} from "@open-rpc/client-js";
import { notifications } from "./Stores/notifications";

const deribit_env = {
  main: "wss://www.deribit.com/ws/api/v2",
  test: "wss://test.deribit.com/ws/api/v2",
};
let current_deribit_env = "main";

let deribit_url = deribit_env[current_deribit_env];
if (localStorage.hasOwnProperty("deribit_env")) {
  current_deribit_env = localStorage.getItem("deribit_env");
  deribit_url =
    current_deribit_env in deribit_env
      ? deribit_env[current_deribit_env]
      : deribit_env.main;
} else {
  localStorage.setItem("deribit_env", "main");
}
const transport = new WebSocketTransport(deribit_url);
const requestManager = new RequestManager([transport]);
export const client = new Client(requestManager);

export const currency = writable("BTC");
export const BTC_PRICE = writable(0);
export const ETH_PRICE = writable(0);
export const custom_price = writable(10000);
export const custom_vol = writable(30);
export const custom_scale = writable(100);
export const table_from = writable(0);
export const table_to = writable(1000000);

const _TickerData = new Map();
const _RAWTickerData = {};
export const TickerData = writable([]);
export const RAWTickerData = writable({});

function ParseTickerData(ticker_data) {
  let strike = parseFloat(ticker_data.instrument_name.split("-")[2]);
  let type = ticker_data.instrument_name.split("-")[3] === "P" ? "PUT" : "CALL";

  let both = {};

  if (!_TickerData.has(strike)) {
    both[type] = ticker_data;
    _TickerData.set(strike, both);
  } else {
    both = _TickerData.get(strike);
    both[type] = ticker_data;
    _TickerData.set(strike, both);
  }

  TickerData.set(_TickerData);

  _RAWTickerData[ticker_data.instrument_name] = ticker_data;
  RAWTickerData.set(_RAWTickerData);
}

export function ParseRPCResponse(response) {
  if (response.method === "heartbeat") {
    if (response.params.type === "test_request") {
      (async () => {
        await client.request({
          method: "public/test",
          params: {},
        });
      })();
    }
  }

  if (response.method === "subscription") {
    if (response.params.channel === "deribit_price_index.btc_usd") {
      BTC_PRICE.set(response.params.data.price);
    }

    if (response.params.channel === "deribit_price_index.eth_usd") {
      ETH_PRICE.set(response.params.data.price);
    }

    if (response.params.channel.includes("ticker.")) {
      ParseTickerData(response.params.data);
    }
  }
}
async function setHeartbeat(interval = 30) {
  try {
    await client
      .request({
        method: "public/hello",
        params: {
          client_name: "Algalon R&D",
          client_version: "0.0.1",
        },
      })
      .then((result) => {
        notifications.set([
          {
            kind: "success",
            title: "Connection to Deribit established",
            subtitle:
              current_deribit_env === "main"
                ? "Using Mainnet"
                : "Using Testnet",
            caption: `Version ${result.version}`,
          },
        ]);
      });

    await client.request({
      method: "public/set_heartbeat",
      params: { interval: interval },
    });
  } catch (error) {
    console.error(error);
  }
}
export async function RPCSubscribe(channels) {
  try {
    await client.request({
      method: "public/subscribe",
      params: { channels: channels },
    });
  } catch (error) {
    console.error(error);
  }
}

export async function RPCUnsubscribe(channels) {
  _TickerData.clear();
  TickerData.set(_TickerData);
  try {
    await client.request({
      method: "public/unsubscribe",
      params: { channels: channels },
    });
  } catch (error) {
    console.error(error);
  }
}

client.onNotification((data) => ParseRPCResponse(data));
client.onError((error) => console.log(error));

(async () => {
  setHeartbeat();
})();

export const instruments = derived(currency, async ($currency) => {
  try {
    const instrumentsRAW = await client.request({
      method: "public/get_instruments",
      params: {
        currency: $currency,
        kind: "option",
      },
    });

    return instrumentsRAW;
  } catch (error) {
    console.error(error);
  }
});

export const expirations = derived(instruments, async ($instruments) => {
  const expirations = await $instruments.then((instruments) => {
    const _exp = new Set();
    instruments.forEach(function callback(instrument) {
      _exp.add(instrument.instrument_name.split("-")[1]);
    });
    let expirations = Array.from(_exp);
    expirations.sort((a, b) => {
      return (
        new Date(
          `${a.match(/\d+/g)[0]} ${a.match(/[A-Z]+/)[0]} 20${
            a.match(/\d+/g)[1]
          }`
        ) -
        new Date(
          `${b.match(/\d+/g)[0]} ${b.match(/[A-Z]+/)[0]} 20${
            b.match(/\d+/g)[1]
          }`
        )
      );
    });
    return expirations;
  });
  return expirations;
});

export const selectedExpiration = writable("");

export const selectedInstruments = derived(
  [selectedExpiration, instruments],
  async ([$selectedExpiration, $instruments]) => {
    if ($selectedExpiration === "") {
      return [];
    }
    const selected = await $instruments.then((instruments) =>
      instruments.filter((inst) =>
        inst.instrument_name.includes($selectedExpiration)
      )
    );
    _TickerData.clear();
    await RPCSubscribe(
      selected.map((i) => `ticker.${i.instrument_name}.100ms`)
    );
    return selected;
  }
);

export const selectedInstrumentsList = derived(
  selectedInstruments,
  async ($selectedInstruments) => {
    const list = await $selectedInstruments.then((instruments) =>
      instruments.map((inst) => inst.instrument_name)
    );
    return list;
  }
);
