<script>
  import { custom_price, custom_vol } from "../store.js";
  import { Slider, ButtonSet, Button } from "carbon-components-svelte";
</script>

<style>
  span[contenteditable="true"] {
    border-bottom: 1px dotted blue;
    font-weight: 500;
  }
</style>

<div class="mb-1">
  <div class="settings-block mb-1">
    <h4 class="mb-025">Settings</h4>
    <p class="mb-025">
      Volatility, %:
      <span contenteditable="true" bind:innerHTML={$custom_vol} />
    </p>
    <Slider
      class="mb-1"
      light
      min={0}
      max={500}
      bind:value={$custom_vol}
      step={1}
      hideTextInput={true} />
    <ButtonSet>
      <Button
        size="small"
        kind="tertiary"
        on:click={() => ($custom_vol = parseFloat($custom_vol) - 5)}>
        -5
      </Button>
      <Button
        size="small"
        kind="tertiary"
        on:click={() => ($custom_vol = parseFloat($custom_vol) + 5)}>
        +5
      </Button>
    </ButtonSet>
  </div>
  <div class="settings-block">
    <p class="mb-025">
      Price, $:
      <span contenteditable="true" bind:innerHTML={$custom_price} />
    </p>
    <Slider
      class="mb-1"
      light
      min={1}
      max={100000}
      bind:value={$custom_price}
      step={1}
      hideTextInput={true} />
    <ButtonSet>
      <Button
        size="small"
        kind="tertiary"
        on:click={() => ($custom_price = parseFloat($custom_price) - 100)}>
        -100
      </Button>
      <Button
        size="small"
        kind="tertiary"
        on:click={() => ($custom_price = parseFloat($custom_price) + 100)}>
        +100
      </Button>
    </ButtonSet>
  </div>
</div>
