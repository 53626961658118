<script>
  import { custom_scale } from "../store.js";
  import { Slider, ButtonSet, Button } from "carbon-components-svelte";
</script>

<style>
  span[contenteditable="true"] {
    border-bottom: 1px dotted blue;
    font-weight: 500;
  }
</style>

<div class="mb-1">
  <div class="settings-block">
    <h4 class="mb-025">Settings</h4>
    <p class="mb-025">
      Scale:
      <span contenteditable="true" bind:innerHTML={$custom_scale} />
    </p>
    <Slider
      class="mb-1"
      light
      min={0}
      max={500}
      bind:value={$custom_scale}
      step={1}
      hideTextInput={true} />

    <ButtonSet>
      <Button
        size="small"
        kind="tertiary"
        on:click={() => ($custom_scale = parseFloat($custom_scale) - 10)}>
        -10
      </Button>
      <Button
        size="small"
        kind="tertiary"
        on:click={() => ($custom_scale = parseFloat($custom_scale) + 10)}>
        +10
      </Button>
    </ButtonSet>
  </div>
</div>
