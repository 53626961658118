<script>
  import Router from "svelte-spa-router";
  import Calc from "./Pages/Calc.svelte";
  import App from "./Pages/App.svelte";
  import Smile from "./Pages/Smile.svelte";
  import Hedge from "./Pages/Hedge.svelte";
  // Componnents
  import Nav from "./Components/Nav.svelte";
  import Notifications from "./Components/Notifications.svelte";
  import Header from "./Components/Header/Header.svelte";

  const routes = {
    "/": Nav,
    "/options": App,
    "/calculations": Calc,
    "/smile": Smile,
    "/hedge": Hedge,

    // Catch-all
    // This is optional, but if present it must be the last
    // "*": NotFound,
  };
</script>

<Header />

<div class="content">
  <Router {routes} />
</div>

<Notifications />
