<script>
  import { routes as links } from "../../Stores/routes";
  import {
    Header,
    HeaderNav,
    HeaderNavItem,
    HeaderNavMenu,
    HeaderUtilities,
    HeaderGlobalAction,
    Modal,
    FormGroup,
    RadioButtonGroup,
    RadioButton,
  } from "carbon-components-svelte";
  import SettingsAdjust20 from "carbon-icons-svelte/lib/SettingsAdjust20";

  let settings_modal = false;

  let deribit_env = "main";
  if (localStorage.hasOwnProperty("deribit_env")) {
    deribit_env = localStorage.getItem("deribit_env");
  }

  function set_settings() {
    localStorage.setItem("deribit_env", deribit_env);
    window.location.reload();
  }
</script>

<Header company="Algalon" platformName="R&D" href="/">
  <HeaderNav>
    <HeaderNavMenu text="Menu">
      {#each links as link}
        <HeaderNavItem text={link.text} href={`/#/${link.url}`} />
      {/each}
    </HeaderNavMenu>
  </HeaderNav>
  <HeaderUtilities>
    <HeaderGlobalAction
      aria-label="Settings"
      on:click={() => {
        settings_modal = !settings_modal;
      }}
      icon={SettingsAdjust20}
    />
  </HeaderUtilities>
</Header>

<Modal
  bind:open={settings_modal}
  modalHeading="Global settings"
  primaryButtonText="Save & Reload"
  secondaryButtonText="Cancel"
  on:click:button--secondary={() => (settings_modal = false)}
  on:open
  on:close
  on:submit={() => set_settings()}
>
  <FormGroup legendText="Deribit API environment:">
    <RadioButtonGroup bind:selected={deribit_env} orientation="vertical">
      <RadioButton labelText="Mainnet" value="main" />
      <RadioButton labelText="Testnet" value="test" />
    </RadioButtonGroup>
  </FormGroup>
</Modal>
