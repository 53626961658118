import dayjs from "dayjs";
import { blackScholes } from "../../bs/bs.js";
import { getDelta } from "../../bs/greeks.js";

export function delta_calculation(current_price, active_ticker) {
  let strike,
    time_to_expiration,
    vol,
    option_type,
    single_delta,
    price = 0,
    delta = 0,
    ntd = 0,
    now = dayjs(),
    expiration,
    exp;

  if (
    (!active_ticker.reversed_condition &&
      current_price >= active_ticker.min_index_price &&
      current_price <= active_ticker.max_index_price) ||
    (active_ticker.reversed_condition &&
      (current_price <= active_ticker.min_index_price ||
        current_price >= active_ticker.max_index_price))
  ) {
    expiration = active_ticker.ticker.split("-")[1];
    exp = `${expiration.match(/\d+/g)[0]} ${expiration.match(/[A-Z]+/)[0]} 20${
      expiration.match(/\d+/g)[1]
    } 11:00:00`;

    option_type = active_ticker.ticker.split("-")[3] === "C" ? "call" : "put";
    strike = parseFloat(active_ticker.ticker.split("-")[2]);
    time_to_expiration = dayjs(exp).diff(now, "y", true);
    vol = active_ticker.mark_iv / 100;
    single_delta = parseFloat(
      getDelta(
        current_price,
        strike,
        time_to_expiration,
        vol,
        0,
        option_type
      ).toFixed(4)
    );

    price = parseFloat(
      blackScholes(
        current_price,
        strike,
        time_to_expiration,
        vol,
        0,
        option_type
      ).toFixed(4)
    );
    delta = parseFloat(
      (
        single_delta *
        active_ticker.size *
        active_ticker.delta_multiplier
      ).toFixed(4)
    );
    ntd = parseFloat(
      (
        (single_delta - price / current_price) *
        active_ticker.size *
        active_ticker.delta_multiplier
      ).toFixed(4)
    );
  }

  return {
    delta: delta,
    ntd: ntd,
  };
}
